import * as React from "react";
import "../styles/style.scss";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const FeaturedListItem = ({ item }) => {
  let cardClass = "";
  const image = getImage(item.frontmatter.image);

  // Check if the image is portrait or landscape orientation
  if (image.width > image.height) {
    cardClass = "col-lg-6 d-flex justify-content-center mb-4";
  } else {
    cardClass = "col-xl-3 col-lg-6 d-flex justify-content-center mb-4";
  }

  return (
    <div className={cardClass}>
      <div className="card shadow">
        <div className="card-img-top">
          <GatsbyImage
            image={image}
            className="rounded feat-item-img"
            alt={item.frontmatter.title}
          />
        </div>
        <div className="card-body featured-body">
          <div>
            <h3 className="card-title">{item.frontmatter.title}</h3>
            <p className="card-text mb-4">{item.frontmatter.headline}</p>
          </div>
          <a className="text-button me-3" href={item.frontmatter.url}>
            View More Details →
          </a>
        </div>
      </div>
    </div>
  );
};

export default FeaturedListItem;
