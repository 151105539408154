import React, { useState } from "react";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    phone: "",
    email: "",
    message: "",
  });
  const [formStatus, setFormStatus] = useState("");

  const handleChange = (e) => {
    let { name, value } = e.target;
    setFormData((state) => ({
      ...state,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const form = e.target;
    let url = "https://getform.io/f/aa033254-e8e0-476c-b049-afb26c05b425";
    let options = {
      method: "POST",
      body: new FormData(form),
    };
    try {
      let response = await fetch(url, options);
      if (response.ok) {
        setFormStatus("Form submitted successfully! Thanks for your inquiry.");
        setFormData({
          name: "",
          phone: "",
          email: "",
          message: "",
        });
      }
    } catch (err) {
      setFormStatus("Uh oh! Something went wrong—please try again.");
    }
  };

  return (
    <form id="contactForm" onSubmit={(e) => handleSubmit(e)}>
      <div className="row align-items-stretch mb-5">
        <div className="col-md-6">
          <div className="form-group mb-3">
            <input
              className="form-control"
              id="name"
              name="name"
              value={formData.name}
              onChange={(e) => handleChange(e)}
              type="text"
              placeholder="Your Name *"
              required
            />
          </div>
          <div className="form-group mb-3">
            <input
              className="form-control"
              id="email"
              name="email"
              value={formData.email}
              onChange={(e) => handleChange(e)}
              type="email"
              placeholder="Your Email *"
              required
            />
          </div>
          <div className="form-group mb-md-0 mb-3">
            <input
              className="form-control"
              id="phone"
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={(e) => handleChange(e)}
              placeholder="Your Phone *"
              required
            />
          </div>
        </div>
        <div className="col-md-6">
          <div className="form-group form-group-textarea mb-md-0">
            <textarea
              className="form-control"
              id="message"
              name="message"
              value={formData.message}
              onChange={(e) => handleChange(e)}
              placeholder="Your Message *"
              rows="4"
              required
            ></textarea>
          </div>
        </div>
      </div>
      <div className="text-center">
        <div className="mb-3">{formStatus}</div>

        <button className="btn btn-primary" id="submitButton" type="submit">
          Send Message
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
