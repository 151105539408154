import React from "react";

const EventPreview = ({ event }) => (
  <div className="col-lg-4 mb-4">
    <div>
      <div className="d-flex align-items-center">
        <p className="event-tag px-2 py-1">{event.frontmatter.label}</p>
      </div>

      <h3>{event.frontmatter.title}</h3>

      <div className="text-muted">
        {new Date(event.frontmatter.datetime).toLocaleString("en-GB", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          timeZoneName: "short",
        })}
      </div>

      <div className="fs-6">{event.frontmatter.headline}</div>

      <div className="d-flex mt-3">
        <a className="text-button me-3" href={event.frontmatter.url}>
          View More Details →
        </a>
      </div>
    </div>
  </div>
);

export default EventPreview;
