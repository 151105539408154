import * as React from "react";
import "../styles/style.scss";
import Layout from "../components/Layout";
import Seo from "../components/Seo";
import ContactForm from "../components/ContactForm";

import { graphql } from "gatsby";
import FeaturedListItem from "../components/FeaturedListItem";
import FeaturedBlogPostCard from "../components/FeaturedBlogPostCard";
import EventPreview from "../components/EventPreview";

// markup
const IndexPage = ({ data }) => {
  const featuredItems = data.featuredItems.nodes;
  const blogposts = data.blogPostsRemark.nodes;
  const nextEvents = data.eventItems.nodes.slice(0, 2);
  const pageContent = data.homePageContent.nodes[0];

  return (
    <Layout page={pageContent}>
      <Seo
        title={pageContent.frontmatter.title}
        description={pageContent.frontmatter.description}
      />
      <div>
        <div className="home-container">
          <h2 className="text-center py-3 mb-4 display-5">Most Popular</h2>

          <div className="row mb-5">
            {featuredItems.map((item) => (
              <FeaturedListItem item={item} key={item.frontmatter.title} />
            ))}
          </div>

          <div className="container d-flex justify-content-center">
            <a href="/training" className="btn btn-primary mt-3 me-3">
              View more training videos
            </a>
            <a href="/publications" className="btn btn-primary mt-3">
              View more books
            </a>
          </div>
        </div>

        <div className="home-container bg-light">
          <div className="container">
            <h2 className="text-center display-5 py-3 mb-4">
              Latest from My Blog
            </h2>
            <FeaturedBlogPostCard post={blogposts[0]} />
            <div className="container d-flex justify-content-center">
              <a href="/blog" className="btn btn-primary mt-3">
                View all blog posts
              </a>
            </div>
          </div>
        </div>

        <div className="home-container">
          <h2 className="text-center py-3 mb-4 display-5">Upcoming Events</h2>

          <div className="row mb-3 justify-content-center">
            {nextEvents.map((event) => (
              <EventPreview event={event} key={event.frontmatter.title} />
            ))}
          </div>

          <div className="container d-flex justify-content-center">
            <a href="/upcomingevents" className="btn btn-primary">
              View all events
            </a>
          </div>
        </div>

        <div className="home-container bg-light">
          <h2 className="text-center mb-3 display-5" id="contact-form">
            Contact Form
          </h2>

          <div className="container">
            <ContactForm />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query homePageQuery {
    homePageContent: allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/index/" }
        frontmatter: { type: { eq: "page_content" } }
      }
    ) {
      nodes {
        frontmatter {
          title
          description
          header_image {
            childImageSharp {
              gatsbyImageData(width: 1200, placeholder: BLURRED)
            }
          }
        }
        html
      }
    }
    featuredItems: allMarkdownRemark(
      filter: { frontmatter: { featured: { eq: true } } }
    ) {
      nodes {
        frontmatter {
          title
          url
          headline
          buttontext
          image {
            childImageSharp {
              gatsbyImageData(
                width: 700
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
      }
    }
    blogPostsRemark: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blogpost" } } }
      sort: { fields: frontmatter___datetime, order: DESC }
    ) {
      nodes {
        frontmatter {
          title
          datetime(formatString: "dddd, D MMMM YYYY")
          header_image {
            childImageSharp {
              gatsbyImageData
            }
          }
        }
        excerpt
        fields {
          slug
        }
      }
    }
    eventItems: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "Upcoming Events" } } }
      sort: { fields: frontmatter___datetime, order: ASC }
    ) {
      nodes {
        frontmatter {
          title
          url
          headline
          buttontext
          datetime
          label
          image {
            childImageSharp {
              gatsbyImageData(
                width: 500
                placeholder: BLURRED
                formats: [AUTO, WEBP]
              )
            }
          }
        }
        html
      }
    }
  }
`;

export default IndexPage;
